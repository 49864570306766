/* eslint-disable camelcase */
// import dynamic from 'next/dynamic';
import React, { PureComponent } from 'react';
import dynamic from 'next/dynamic';
import DATA_TEMPLATES from './dataImport';

const PageNotFound = dynamic(() => import('@/componentWebs/NoFindPage'), {
  ssr: true,
  loading: () => null,
})

class Control extends PureComponent {
  render() {
    const { typeLayout } = this.props;
    try {
      const Templates = DATA_TEMPLATES[`${typeLayout}`];
      if (Templates) {
        return React.createElement(Templates, {
          ...this.props,
        });
      }
      return <PageNotFound />;
    } catch (error) {
      return <PageNotFound />;
    }
  }
}

export default Control;
