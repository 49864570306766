/* eslint-disable camelcase */
/* eslint-disable camelcase */
/* eslint-disable camelcase */
import dynamic from 'next/dynamic';

const home = dynamic(() => import(`./layoutData/home`), {
  ssr: true,
  loading: () => null,
});
const category = dynamic(() => import(`./layoutData/category`), {
  ssr: true,
  loading: () => null,
});
const detail = dynamic(() => import(`./layoutData/detail`), {
  ssr: true,
  loading: () => null,
});
const user = dynamic(() => import(`./layoutData/user`), {
  ssr: true,
  loading: () => null,
});


const DATA_TEMPLATES = {
  home,
  category,
  detail,
  user
};
export default DATA_TEMPLATES;
